import {
  DiscordLogoIcon,
  InfoCircledIcon,
  LockClosedIcon,
  Pencil2Icon,
  TwitterLogoIcon,
} from "@radix-ui/react-icons"
import { MediumLogo, SundaeSwapLogoNeon } from "@sundae/ui-toolkit"
import { initiateClient } from "client-theme"

import clientTranslations, { TClientTranslationType } from "./translations"

import "./styles.scss"

initiateClient<TClientTranslationType>({
  brand: {
    logos: {
      header: <SundaeSwapLogoNeon />,
      notFound: <SundaeSwapLogoNeon width={200} />,
    },
  },
  clientNamespace: "sundae-vesting",
  clientTranslations,
  externalRoutes: {
    faq: "#",
    protocol: "#",
  },
  quickMenuPopover: (currentLocale) => [
    {
      title: clientTranslations[currentLocale].quickMenu.about,
      icon: <InfoCircledIcon />,
      link: "#",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.governance,
      icon: <Pencil2Icon />,
      link: "#",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.discord,
      icon: <DiscordLogoIcon />,
      link: "#",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.twitter,
      icon: <TwitterLogoIcon />,
      link: "#",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.medium,
      icon: <MediumLogo />,
      link: "#",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.legal,
      icon: <LockClosedIcon />,
      link: "#",
    },
  ],
})
